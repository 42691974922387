import styled from '@emotion/styled';
import LogoEntero from '../../common/assets/logo-entero.png';
import LogoCorto from '../../common/assets/logo-mamam.png';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { State } from '../../common/entities/State';
import { BaseResponse } from '../../common/interfaces/base-response';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Pill from '../../common/components/Pill';
import MamamHeader from '../../common/components/MamamH3';

const WINDOW_RESIZE = 1000;
const LogoImg = styled('img')(() => ({
	width: '50%',

	marginInline: 'auto',
	height: 'auto',
	maxHeight: '500px',
	minHeight: '100px',
	'@media(max-width:1000px)': {
		minWidth: '80%',
	},
}));
export default function Home() {
	const [windowSize, setWindowSize] = useState([
		window.innerWidth,
		window.innerHeight,
	]);
	const [states, setStates] = useState<BaseResponse<State>>();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		axios
			.request({
				method: 'GET',
				url: `/states`,
			})
			.then((response) => {
				setStates(response.data);
			})
			.catch((error) => console.error(error))
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize([window.innerWidth, window.innerHeight]);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const columns: GridColDef[] = [
		{
			field: 'name',
			headerName: 'Nombre',
			width: 270,
			flex: 1,
			renderCell(params) {
				const row = params.row;
				return <Pill text={row.name} color={row.label_color} />;
			},
		},
		{
			field: 'description',
			headerName: 'Descripción',
			width: 620,
			flex: 4,

			renderCell(params) {
				const row = params.row;
				return <p>{row.description || '---'}</p>;
			},
		},
	];
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',

				alignItems: 'center',
			}}
		>
			<LogoImg
				src={windowSize[0] < WINDOW_RESIZE ? LogoCorto : LogoEntero}
				alt="logo-mamam"
			/>
			<div style={{ width: '80%' }}>
				<h3 style={{ fontSize: '1.2rem' }}>
					<span style={{ color: '#da8054' }}>¿</span>
					<span style={{ color: 'rgb(47,46,45)' }}>
						Qué significa cada estado
					</span>
					<span style={{ color: '#da8054' }}>?</span>
				</h3>
				<DataGrid
					autoHeight
					rows={states?.data || []}
					columns={columns}
					loading={loading}
					disableColumnMenu
					disableColumnSelector
					hideFooter
				/>
			</div>
		</div>
	);
}
